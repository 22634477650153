import {
  OAuthProvider,
  signInWithPopup,
  fetchSignInMethodsForEmail,
  UserCredential,
} from 'firebase/auth';
import i18next from 'i18next';
import { AuthErrorCodes } from 'firebase/auth';
import { captureException } from '@sentry/browser';

import '@pages/app';
import '@components/passwordLogin';
import { showBottomSheet } from '@components/bottomSheet';
import { globalSpinnerController } from '@/components/Spinner';
import {
  auth,
  authProvidersMap,
  getIsNewUser,
  idTokenHandler,
  isCalledFromParentSite,
  linkAccountWithPopup,
} from '@services/authService';
import {
  isMobile,
  isAppleDeviceWebApp,
  isKakaoOrNaver,
  isSamsungInternet,
} from '@utils/uaParser';
import { sharedData, setCredentialForLinkAccount } from '@shares/dataManager';
import {
  isAnchorElement,
  isDialogElement,
  isHTMLElement,
} from '@utils/elementTypeGuards';
import { provider, signInMethodType } from '@/types/auth';
import { FirebaseError } from 'firebase/app';
import { PAGE_PATHS, loginProblemMailScheme } from '@constants/url';
import { webkitMessageHandler } from '@/utils/webkitMessageHandler';
import { ACTION_TYPES } from '@constants/actions';
import saveProviderType from '@utils/saveProviderType';
import getIsNeedOnboarding from '@/utils/getIsNeedOnboarding';

let socialSignInError: FirebaseError | null = null;
let linkedMethod: signInMethodType | undefined = undefined;
let isSamsungBrowserGooglLoginWarningBottomSheetShown = false;

const BLOCKED_LOGIN_PROVIDERS_IN_EMBEDDED_BROWSER = ['google'];

const SocialLoginContainer = document.getElementById(
  'social-login-container',
) as HTMLElement;
const PasswordLoginContainer = document.getElementById(
  'password-login-container',
) as HTMLElement;
const googleLoginText = SocialLoginContainer.querySelector('#google-login');
const facebookLoginText = SocialLoginContainer.querySelector('#facebook-login');
const appleLoginText = SocialLoginContainer.querySelector('#apple-login');
const emailLoginText = SocialLoginContainer.querySelector('#email-login');
const emailSignUpText = SocialLoginContainer.querySelector('#sign-up');
const problemWithSignIn = SocialLoginContainer.querySelector(
  '#problem-with-signIn',
);
const googleLoginEl = SocialLoginContainer.querySelector('#google-login-btn');
const facebookLoginEl = SocialLoginContainer.querySelector(
  '#facebook-login-btn',
);
const appleLoginEl = SocialLoginContainer.querySelector('#apple-login-btn');
const emailLoginEl = SocialLoginContainer.querySelector('#email-login-btn');
const emailSignUpEl = SocialLoginContainer.querySelector('#sign-up-btn');
const dialog = document.querySelector('dialog');

const dialogOk = dialog?.querySelector('.ok');
const dialogCancel = dialog?.querySelector('.cancel');
const dialogTitle = dialog?.querySelector('#dialog_title');
const dialogDesc1 = dialog?.querySelector('#desc1');
const ballonList = document.querySelectorAll('.ballon');

try {
  if (
    !isHTMLElement(googleLoginText) ||
    !isHTMLElement(facebookLoginText) ||
    !isHTMLElement(appleLoginText) ||
    !isHTMLElement(emailLoginText) ||
    !isHTMLElement(emailSignUpText) ||
    !isHTMLElement(googleLoginEl) ||
    !isHTMLElement(facebookLoginEl) ||
    !isHTMLElement(appleLoginEl) ||
    !isHTMLElement(emailLoginEl) ||
    !isHTMLElement(emailSignUpEl) ||
    !isDialogElement(dialog) ||
    !isHTMLElement(dialogOk) ||
    !isHTMLElement(dialogCancel) ||
    !isHTMLElement(dialogTitle) ||
    !isHTMLElement(dialogDesc1) ||
    !isAnchorElement(problemWithSignIn) ||
    !Array.from(ballonList).every((ballon) => isHTMLElement(ballon))
  ) {
    throw new Error('Element not found');
  }

  googleLoginText.textContent = i18next.t('googleSignIn');
  facebookLoginText.textContent = i18next.t('facebookLogin');
  appleLoginText.textContent = i18next.t('appleLogin');
  emailLoginText.textContent = i18next.t('signIn');
  emailSignUpText.textContent = i18next.t('signUpButtonLabel');
  problemWithSignIn.textContent = i18next.t('problemWithSignIn');
  ballonList.forEach((ballon) => {
    ballon.textContent = i18next.t('recentLogin');
  });

  dialogTitle.textContent = i18next.t('index.dialog.title');
  dialogDesc1.textContent = i18next.t('index.dialog.desc1');
  dialogOk.textContent = i18next.t('index.dialog.linkAccount');
  dialogCancel.textContent = i18next.t('index.dialog.cancel');

  const currentLanguage = i18next.language;

  if (currentLanguage === 'ko') {
    problemWithSignIn.href = loginProblemMailScheme.ko;
  } else problemWithSignIn.href = loginProblemMailScheme.en;

  const handleNeedConfirmationError = (error: FirebaseError) => {
    socialSignInError = error;
    dialog.showModal();
  };

  const getLoginUrlWithCurrentQuery = () => {
    const currentUrl = new URL(window.location.href);
    currentUrl.pathname = '/login';
    return currentUrl;
  };

  const showPasswordLoginContainer = async () => {
    SocialLoginContainer.style.display = 'none';
    PasswordLoginContainer.style.display = 'block';
    dialog.close();

    history.pushState(
      { isLinkingSocialAccount: true },
      '',
      getLoginUrlWithCurrentQuery(),
    );
  };

  const handleEmbeddedBrowserLogin = () => {
    showBottomSheet('embeddedBrowser');
  };

  const webAppLogin = (providerType: provider) => {
    webkitMessageHandler(providerType);
  };

  const handleCredential = async (credential: UserCredential) => {
    const idToken = await credential.user.getIdToken();
    const isNewUser = getIsNewUser(credential);
    const actionType = isNewUser
      ? ACTION_TYPES.SOCIAL_SIGNUP
      : ACTION_TYPES.SOCIAL_SIGNIN;

    const { customToken } = await idTokenHandler({
      idToken,
      actionType,
      isNewUser,
    });

    if (isCalledFromParentSite) {
      window.opener.postMessage(
        {
          result: 'success',
          data: customToken,
        },
        '*',
      );
    } else {
      await redirectToUpdatedCallbackUrl({
        idToken,
        customToken,
        isNewUser,
        uid: credential.user.uid,
      });
    }
  };

  const handleDefaultLogin = async (providerType: provider) => {
    const provider = authProvidersMap[providerType];
    const userCredential = await signInWithPopup(auth, provider);
    await handleCredential(userCredential);

    saveProviderType(userCredential.providerId);
  };

  const handleError = (err: unknown) => {
    if (
      err instanceof FirebaseError &&
      err.code === AuthErrorCodes.NEED_CONFIRMATION
    ) {
      handleNeedConfirmationError(err);
    }
  };

  const socialLoginHandler = async (providerType: provider) => {
    linkedMethod = providerType;
    // 카카오, 네이버앱에서 접속하는 경우 로그인 불가하기 때문에 대체 방안 제공하는 바텀시트 띄움
    if (
      BLOCKED_LOGIN_PROVIDERS_IN_EMBEDDED_BROWSER.includes(providerType) &&
      isMobile &&
      isKakaoOrNaver
    ) {
      handleEmbeddedBrowserLogin();
      return;
    }

    if (
      isSamsungInternet &&
      providerType === 'google' &&
      !isSamsungBrowserGooglLoginWarningBottomSheetShown
    ) {
      showBottomSheet('samsungBrowser');
      isSamsungBrowserGooglLoginWarningBottomSheetShown = true;
      return;
    }

    // 스튜디오 웹앱에서 구글 로그인인 경우 네이티브 코드 호출하여 로그인
    if (
      isAppleDeviceWebApp &&
      (providerType === 'google' || providerType === 'apple')
    ) {
      webAppLogin(providerType);
      return;
    }

    // 이외 모든 로그인 시도는 기본 웹 로그인 방식으로 처리
    try {
      globalSpinnerController.showSpinner();
      await handleDefaultLogin(providerType);
    } catch (err) {
      captureException(err);
      handleError(err);
      globalSpinnerController.hideSpinner();
    }
  };

  const emailLoginClickHandler = async () => {
    location.href = 'login' + window.location.search;
  };

  const emailSignUpClickHandler = () => {
    location.href = `${PAGE_PATHS.REGISTER}` + window.location.search;
  };

  const dialogOkClickHandler = async (
    socialSignInError: FirebaseError | null,
  ) => {
    if (socialSignInError === null) return;

    try {
      const credentialForLinkAccount =
        OAuthProvider.credentialFromError(socialSignInError);
      setCredentialForLinkAccount(credentialForLinkAccount);
      //TODO: 확인 필요
      const email = (socialSignInError?.customData?.email || '') as string;

      const signInMethods = await fetchSignInMethodsForEmail(auth, email);
      const signInMethod = signInMethods[0].split('.')[0];

      if (signInMethod === 'password') {
        showPasswordLoginContainer();
        return;
      }

      if (credentialForLinkAccount) {
        globalSpinnerController.showSpinner();
        const userCredential = await linkAccountWithPopup(
          credentialForLinkAccount,
          signInMethod as provider,
        );
        const idToken = await userCredential.user.getIdToken();
        const isNewUser = getIsNewUser(userCredential);
        const uid = userCredential.user.uid;

        const { customToken } = await idTokenHandler({
          idToken,
          actionType: ACTION_TYPES.SOCIAL_SIGNIN,
        });

        if (isCalledFromParentSite) {
          window.opener.postMessage(
            {
              result: 'success',
              data: customToken,
            },
            '*',
          );
        } else {
          await redirectToUpdatedCallbackUrl({
            idToken,
            customToken,
            isNewUser,
            linkedMethod,
            uid,
          });
        }

        dialog.close();
      }
    } catch (e) {
      captureException(e);
      globalSpinnerController.hideSpinner();
    }
  };

  const dialogCancelBtnClickHandler = () => {
    dialog.close();
  };

  googleLoginEl.addEventListener('click', () => socialLoginHandler('google'));
  facebookLoginEl.addEventListener('click', () =>
    socialLoginHandler('facebook'),
  );
  appleLoginEl.addEventListener('click', () => socialLoginHandler('apple'));
  emailLoginEl.addEventListener('click', emailLoginClickHandler);
  emailSignUpEl.addEventListener('click', emailSignUpClickHandler);
  dialogOk.addEventListener('click', () =>
    dialogOkClickHandler(socialSignInError),
  );
  dialogCancel.addEventListener('click', dialogCancelBtnClickHandler);

  const redirectToUpdatedCallbackUrl = async ({
    idToken,
    customToken,
    isNewUser,
    linkedMethod,
    uid,
  }: {
    idToken: string;
    customToken: string;
    isNewUser: boolean;
    linkedMethod?: signInMethodType;
    uid: string;
  }) => {
    try {
      const { callbackUrl, platform } = sharedData.queries;

      if (callbackUrl) {
        const tokenParam = platform === 'web' ? 'idToken' : 'token';
        const tokenValue = platform === 'web' ? idToken : customToken || '';

        const updatedCallbackUrl = new URL(callbackUrl);
        updatedCallbackUrl.searchParams.append(tokenParam, tokenValue);

        const isNeedOnboarding = await getIsNeedOnboarding({
          uid,
          token: idToken,
        });

        if (isNeedOnboarding) {
          updatedCallbackUrl.pathname = '/onboarding';
        }

        const redirectUrl = isNewUser
          ? `${
              PAGE_PATHS.SOCIAL_REGISTER_DONE
            }?callbackUrl=${encodeURIComponent(updatedCallbackUrl.toString())}`
          : linkedMethod
          ? `${PAGE_PATHS.CONNECT_DONE}?callbackUrl=${encodeURIComponent(
              updatedCallbackUrl.toString(),
            )}&linkedMethod=${linkedMethod}`
          : updatedCallbackUrl.toString();

        window.location.href = redirectUrl;
      }
    } catch (e) {
      console.error(e);
      captureException(e);
    }
  };

  const providerType = localStorage.getItem('providerType');
  if (providerType) {
    const ballon = document.querySelector(`.ballon-${providerType}`);
    if (isHTMLElement(ballon)) {
      ballon.style.visibility = 'visible';
    }
  }
} catch (e) {
  captureException(e);
}
