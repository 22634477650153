import i18next from 'i18next';
import i18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';

i18next.use(i18nextBrowserLanguageDetector).init({
  fallbackLng: 'en',
  detection: {
    order: ['navigator'],
  },
  resources: {
    en: {
      translation: {
        recentLogin: 'Recent login',
        languageParameter: 'en',
        google: 'Google',
        facebook: 'Facebook',
        apple: 'Apple',
        facebookLogin: 'Continue with Facebook',
        appleLogin: 'Continue with Apple',
        emailLabel: 'Email Address',
        emailNotVerfied: 'Email not verified',
        emailRequired: 'Email is required',
        emailSentNotiBack: '.',
        emailSentNotiFront: 'A verification email has been sent to ',
        emailValidation: 'Must be a valid email',
        firstName: 'First name',
        firstNameRequired: 'First name is required',
        forgotPassword: 'Forgot password',
        googleSignIn: 'Continue with Google',
        lastName: 'Last name',
        lastNameRequired: 'Last name is required',
        or: 'or',
        passwordConfirm: 'Confirm password',
        passwordLabel: 'Password',
        passwordRequired: 'Password is required',
        passwordResetButtonLabel: 'Reset password',
        passwordSame: 'Both password need to be the same',
        passwordValidation: 'Must be at least 8 characters',
        rememberMe: 'Remember Me',
        logIn: 'Login',
        signIn: 'Sign in with Email',
        signUp: 'Sign up',
        camerafiSignUp: 'CameraFi Sign up',
        signUpButtonLabel: 'Sign up with Email',
        emailSaveAlarm:
          'To protect your privacy, please use it only on your own device',
        weakPassword: 'Password strength is too weak',
        wrongPassword: 'Please check your password and try again.',
        check: 'Check',
        email: 'Email',
        nickname: 'Name',
        password: 'Password',
        passwordCheck: 'Password Confirm',
        loginUsedEmailFront: 'This address is already been used for ',
        loginUsedEmailBack: ' login.',
        loginWithExistAccount: 'Please login with your existing account.',
        useOtherBrowserToLogin:
          'Please use another mobile browser (Chrome, Safari).',
        emailEmpty: 'Please enter your email.',
        nicknameEmpty: 'Please enter your name.',
        passwordEmpty: 'Please enter your password.',
        passwordCheckEmpty: 'Please enter your password confirm.',
        emailErrorDefault:
          'Please enter a valid email address for email verification.',
        nicknameErrorDefault:
          '20 characters or less, can only use letters and numbers',
        passwordErrorDefault:
          '8-20 characters, can use letters, numbers and special characters',
        emailError: 'Please enter a valid email address.',
        nicknameError: 'Name can only use letters and numbers.',
        passwordError:
          'Enter the password using letters, numbers, and special characters in 8~20 characters.',
        passwordCheckError: 'The password you entered does not match.',
        userNotFound: 'This is an unsigned email.',
        saveId: 'Save Id',
        signUpComplete: 'Sign up complete!',
        resetPw: 'Reset password',
        cancel: 'Cancel',
        signUpCompleteDescription:
          'CameraFi account registration is completed.',
        allServiceAvailable:
          'You can use all the services of CameraFi with one account.',
        emailVerifyRequired:
          'To use all fuctions of CameraFi, please click the link in the email to complete the email verificatrion.',
        goToMain: 'Go to Dashboard',
        deleteAgreementTitle: 'Do you want to delete your account?',
        deleteAgreementWarning:
          'Please read the precautions before deleting your account',
        deleteAgreementWarning1:
          'Deleting your CameraFi account does not cancel your subscription. Please confirm and manage your subscription before deleting your account.',
        deleteAgreementSubWarning1:
          'CameraFi Live: You can check and cancel your subscription on the Google Play Store or App Store.',
        deleteAgreementSubWarning2:
          "CameraFi Studio: You can check and cancel your subscription on CameraFi Studio's account page.",

        deleteAgreementWarning2:
          ' Before deleting your CameraFi account, you must stop using your channel membership and check your channel membership revenue.',
        deleteAgreementSubWarning3_1: 'Stop using channel membership',
        deleteAgreementSubWarning3_2:
          "Deletes all levels and members, refunds this month's payment to members (deducted from channel revenue).",
        deleteAgreementSubWarning3_3:
          '*Account deletion is not possible without deactivation.',
        deleteAgreementSubWarning4_1: 'Check revenue',
        deleteAgreementSubWarning4_2:
          'After deleting the account, you can’t make any requests and unclaimed revenue will be automatically forfeited.',
        deleteAgreementWarning3:
          'Once the CameraFi account deletion process is complete, all information on CameraFi live, CameraFi Studio will be permanently lost and cannot be recovered.',
        deleteAgreementWarning4:
          'You will no longer be able to log in to any CameraFi services (CameraFi Live or CameraFi Studio) using this account.',
        deleteAgreementWarning5:
          'If you rejoin CameraFi, your previous information cannot be restored.',
        deleteAgreementDialogTitle: 'Stop using the channel membership',
        deleteAgreementDialogText1:
          'Account deletion is only possible after stop using the channel membership.',
        deleteAgreementDialogText2:
          "If you stop, all levels will be deleted, and you can’t have any members. All members will be refunded for this month's payments. Refund amounts will be deducted from the channel’s revenue.",
        deleteAgreementDialogText3:
          '[Settings > Membership >  ⁝  > Stop channel membership]',

        deleteAgreementDialogCancelBtn: 'Cancel',
        deleteAgreementDialogStopBtn: 'Ok',

        deleteAgreementCheckLabel:
          'I have read and agree to the deletion of my account.',
        deleteAgreementCancelBtn: 'Cancel',
        deleteAgreementVerifyBtn: 'Continue deleting account',
        deleteTitle: 'Delete Account',
        deleteWarning:
          'Please note that deleting your account is permanent and cannot be undone.',
        deleteWarningDetail:
          'By proceeding, all associated information across our services (CameraFi Live, CameraFi Studio, CameraFi Shopping) will be permanently removed. Once deleted, the information cannot be recovered. Please consider your decision carefully before proceeding.',
        deleteCancelBtn: 'Cancel',
        deleteVerifyBtn: 'Delete Account',
        deleteCompleteTitle: 'Account Deleted',
        deleteCompleteDescription:
          'Your CameraFi account has been successfully deleted.',
        deleteCompleteBtn: 'OK',
        deleteFailTitle: 'Account Deletion Failed',
        deleteFailDescription1:
          'Sorry, the account deletion has failed for an unknown reason.',
        deleteFailDescription2: 'Please try again in a few moments.',
        deleteFailBtn: 'OK',
        problemWithSignIn: 'Having trouble signing in?',
        bottomSheet: {
          embeddedBrowser: {
            title: 'For Google login, change your browser',
            description: "Google's security policy limits login on this app",
            btn: {
              chrome: 'Open with Google Chrome',
              defaultBrowser: 'Open with Default Browser',
              copyLink: 'Copy Link',
              close: 'Close',
            },
          },
          samsungBrowser: {
            title: 'Click outside the email address field.',
            description:
              'Samsung Internet Browser has an error with Google login. Please click outside the email field.',
            btn: {
              close: 'Close',
            },
          },
        },
        index: {
          notification: {
            linkCopy: 'Link copied',
          },
          dialog: {
            title: 'The account is already registered.',
            desc1:
              'To link your accounts, please log in with your existing account and complete member verification.',
            linkAccount: 'Link your account',
            cancel: 'Cancel',
          },
        },
        login: {
          btn: {
            login: 'Login',
            link: 'Link account',
            forgetPw: 'Forgot your password?',
            resetPw: 'Reset password',
          },
        },
        register: {
          userExist: {
            email: 'This email is already registered.',
            google: 'This email is already registered by Google.',
            facebook: 'This email is already registered by Facebook.',
            apple: 'This email is already registered by Apple',
          },
          dialog: {
            desc1: 'Do you want to login with your existing account?',
            loginWithExistAccount: 'Login with existing account',
            cancel: 'Cancel',
          },
        },
        resetPassword: {
          title: 'Reset Password',
          subtitle:
            'Enter the email address you used when signing up, and we will send you a password reset email.',
          button: 'Reset Password',
          placeholder: 'Email',
          error: {
            notSignedInByPassword:
              'This is not an account signed in by password.',
            notEmailFormat: 'Please enter a valid email address.',
          },
          success: {
            title: 'Password Reset Email Sent',
            description:
              'A password reset email has been sent. Please check your email.',
          },
        },
        connectDone: {
          title: 'Connection completed',
          description: `Your CameraFi account has been connected to your {{type}} account.`,
          button: 'OK',
          google: 'Google',
          facebook: 'Facebook',
          apple: 'Apple',
          email: 'email',
        },
      },
    },
    ko: {
      translation: {
        recentLogin: '최근 로그인',
        languageParameter: 'kor',
        google: '구글',
        facebook: '페이스북',
        apple: '애플',
        facebookLogin: '페이스북으로 로그인',
        appleLogin: '애플로 로그인',
        emailLabel: '이메일',
        emailNotVerfied: '인증되지 않은 이메일입니다.',
        emailRequired: '이메일을 입력하세요.',
        emailSentNotiBack: '로 전송되었습니다.',
        emailSentNotiFront: '인증 메일이 ',
        emailValidation: '올바른 이메일 주소를 입력해 주세요.',
        firstName: '이름',
        firstNameRequired: '이름을 입력하세요.',
        forgotPassword: '비밀번호 초기화하기',
        googleSignIn: '구글로 로그인',
        lastName: '성',
        lastNameRequired: '성을 입력하세요.',
        or: '또는',
        passwordConfirm: '비밀번호 확인',
        passwordLabel: '비밀번호',
        passwordRequired: '비밀번호를 입력하세요.',
        passwordResetButtonLabel: '비밀번호 초기화',
        passwordSame: '비밀번호가 다릅니다.',
        passwordValidation: '비밀번호는 최소 8글자이어야 합니다.',
        rememberMe: 'ID 저장하기',
        logIn: '로그인',
        signIn: '이메일로 로그인',
        signUp: '회원가입',
        camerafiSignUp: '카메라파이 회원가입',
        signUpButtonLabel: '이메일로 회원가입',
        emailSaveAlarm: '개인 정보 보호를 위해 본인 기기에서만 이용해주세요.',
        weakPassword: '비밀번호 보안수준이 너무 약합니다.',
        wrongPassword: '비밀번호가 틀렸습니다. 확인 후 다시 시도해주세요.',
        check: '확인',
        email: '이메일',
        nickname: '닉네임',
        password: '비밀번호',
        passwordCheck: '비밀번호 확인',
        loginUsedEmailFront: '이 주소는 이미 ',
        loginUsedEmailBack: ' 로그인에 사용되었습니다.',
        loginWithExistAccount: '기존 계정으로 로그인 하세요.',
        useOtherBrowserToLogin:
          '다른 모바일 브라우저(Chrome, Safari)를 이용해 주세요.',
        emailEmpty: '이메일을 입력해 주세요.',
        nicknameEmpty: '닉네임을 입력해 주세요.',
        passwordEmpty: '비밀번호를 입력해 주세요.',
        passwordCheckEmpty: '비밀번호 확인을 입력해 주세요.',
        emailErrorDefault: '이메일 인증을 위해 정확한 이메일을 입력해주세요',
        nicknameErrorDefault: '20자 이하, 문자/숫자 사용 가능',
        passwordErrorDefault: '8-20자, 문자/숫자/특수문자 사용 가능',
        emailError: '올바른 이메일 주소를 입력해 주세요.',
        nicknameError: '닉네임은 문자, 숫자만 사용할 수 있습니다.',
        passwordError:
          '비밀번호는 문자, 숫자, 특수 문자를 사용하여 8~20자로 입력해 주세요.',
        passwordCheckError: '입력하신 비밀번호와 일치하지 않습니다.',
        userNotFound: '가입되지 않은 이메일입니다.',
        saveId: '아이디 저장',
        signUpComplete: '가입 완료!',
        resetPw: '비밀번호 재설정',
        cancel: '취소',
        signUpCompleteDescription: '카메라파이 계정 가입이 완료되었습니다.',
        allServiceAvailable:
          '카메라파이 계정 하나로 CameraFi의 모든 서비스를 이용하실 수 있습니다.',
        emailVerifyRequired:
          '카메라파이의 모든 기능 사용을 위해 이메일에 포함된 링크를 클릭하여 이메일 인증을 완료해 주세요.',
        goToMain: '메인으로 이동',
        deleteAgreementTitle: '계정을 삭제하시겠습니까?',
        deleteAgreementWarning: '계정 삭제 전에 유의 사항을 꼭 확인해 주세요.',
        deleteAgreementWarning1:
          '카메라파이 계정을 삭제하더라도 구독 상품은 해지되지 않습니다. 계정 삭제 전 구독 여부를 확인해 주세요.',
        deleteAgreementSubWarning1:
          '카메라파이 라이브: 구글 플레이 스토어 또는 앱 스토어에서 구독 여부를 확인/취소할 수 있습니다.',
        deleteAgreementSubWarning2:
          '카메라파이 스튜디오: 카메라파이 스튜디오의 계정 페이지에서 구독 여부를 확인/취소할 수 있습니다.',
        deleteAgreementWarning2:
          '카메라파이 계정 삭제 전 채널 멤버십 사용을 중지하고, 채널 멤버십 수익금을 확인해야 합니다.',
        deleteAgreementSubWarning3_1: '채널 멤버십 사용 중지하기',
        deleteAgreementSubWarning3_2:
          '등급 및 회원 전체 삭제, 회원에게 이번달 결제 금액 환불(채널 수익금에서 차감)',
        deleteAgreementSubWarning3_3: '* 미진행 시 탈퇴 불가',
        deleteAgreementSubWarning4_1: '수익금 확인하기',
        deleteAgreementSubWarning4_2:
          '계정 삭제 후 정산 신청 불가능, 미신청 시 수익금 자동 소멸 예정',
        deleteAgreementWarning3:
          '카메라파이 계정 삭제 처리가 완료되면 카메라파이 라이브, 카메라파이 스튜디오, 카메라파이 쇼핑의 모든 정보가 사라지고 복구가 불가능합니다.',
        deleteAgreementWarning4:
          '더 이상 해당 계정으로 카메라파이의 모든 서비스(카메라파이 라이브, 카메라파이 스튜디오, 카메라파이 쇼핑)에 로그인할 수 없게 됩니다.',
        deleteAgreementWarning5:
          '카메라파이에 재가입해도 모든 정보는 복구되지 않습니다.',
        deleteAgreementDialogTitle: '채널 멤버십 사용을 중지해 주세요.',
        deleteAgreementDialogText1:
          '채널 멤버십 사용 중지 후 계정 삭제가 가능합니다.',
        deleteAgreementDialogText2:
          '사용을 중지하면 모든 등급이 삭제되어 더 이상 회원을 보유할 수 없게 되며, 모든 회원에게 이번달 결제 금액이 환불됩니다. 환불 금액은 채널 수익금에서 차감됩니다.',
        deleteAgreementDialogText3:
          '[설정 > 멤버십 설정 >  ⁝  > 채널 멤버십 사용 중지]',

        deleteAgreementDialogCancelBtn: '취소',
        deleteAgreementDialogStopBtn: '확인',
        deleteAgreementCheckLabel:
          '유의 사항을 모두 확인하였으며 계정 삭제에 동의합니다.',
        deleteAgreementCancelBtn: '취소',
        deleteAgreementVerifyBtn: '계정 삭제 계속하기',
        deleteTitle: '계정 삭제',
        deleteWarning: '계정 삭제는 복구가 불가능합니다.',
        deleteWarningDetail:
          '계정을 삭제하기 버튼을 클릭하면 지금 회원님이 해당 계정으로 사용하시는 모든 서비스(카메라파이 라이브, 카메라파이 스튜디오, 카메라파이 쇼핑)의 정보가 삭제됩니다. 삭제된 정보는 복구할 수 없으니 신중하게 결정해 주세요.',
        deleteCancelBtn: '취소',
        deleteVerifyBtn: '계정 삭제하기',
        deleteCompleteTitle: '카메라파이 계정 삭제 완료',
        deleteCompleteDescription: '카메라파이 계정 삭제가 완료되었습니다.',
        deleteCompleteBtn: '확인',
        deleteFailTitle: '계정 삭제 실패',
        deleteFailDescription1: '알 수 없는 이유로 계정 삭제에 실패했습니다.',
        deleteFailDescription2: '잠시 후 다시 시도해 주세요.',
        deleteFailBtn: '확인',
        problemWithSignIn: '로그인에 문제가 있으신가요?',
        bottomSheet: {
          embeddedBrowser: {
            title: '구글 로그인을 하려면 다른 브라우저로 접속해주세요.',
            description:
              'Google의 보안 정책으로 인해 현재 앱에서의 구글 로그인이 제한됩니다.',
            btn: {
              chrome: 'Google Chrome 으로 열기',
              defaultBrowser: '기본 브라우저로 열기',
              copyLink: '링크 복사하기',
              close: '닫기',
            },
          },
          samsungBrowser: {
            title: '이메일 주소 영역 바깥을 클릭해 주세요.',
            description:
              '삼성 인터넷 브라우저에서 구글 로그인에 오류가 있습니다. 정상적인 로그인을 위해 이메일 주소 영역 바깥을 클릭해 주세요.',
            btn: {
              close: '닫기',
            },
          },
        },
        index: {
          notification: {
            linkCopy: '링크가 복사되었습니다.',
          },
          dialog: {
            title: '이미 가입된 계정입니다.',
            desc1:
              '계정을 연동하시려면 기존 계정으로 로그인하여 회원 인증을 완료해 주세요.',
            linkAccount: '계정 연동하기',
            cancel: '취소',
          },
        },
        login: {
          btn: {
            login: '로그인',
            link: '계정 연동하기',
          },
          forgetPw: '비밀번호를 잊으셨나요?',
          resetPw: '비밀번호 재설정',
        },
        register: {
          userExist: {
            google: '구글로 이미 가입된 이메일입니다.',
            facebook: '페이스북으로 이미 가입된 이메일입니다.',
            apple: '애플로 이미 가입된 이메일입니다.',
            email: '이미 가입된 이메일입니다.',
          },
          dialog: {
            desc1: '기존 계정으로 로그인하시겠습니까?',
            loginWithExistAccount: '기존 계정으로 로그인',
            cancel: '취소',
          },
        },
        resetPassword: {
          title: '비밀번호 재설정',
          subtitle:
            '회원 가입시 사용한 이메일을 입력하시면 비밀번호 재설정 메일을 보내드립니다.',
          button: '비밀번호 재설정하기',
          placeholder: '이메일',
          error: {
            notSignedInByPassword: '비밀번호로 가입한 계정이 아닙니다.',
            notEmailFormat: '올바른 이메일 주소를 입력해주세요.',
          },
          success: {
            title: '재설정 이메일 발송',
            subtitle:
              '비밀번호 재설정 이메일이 발송되었습니다. 이메일을 확인해주세요.',
          },
        },
        connectDone: {
          title: '연동 완료',
          description: `회원님의 카메라파이 계정이 {{type}} 계정과 연동되었습니다.`,
          button: '확인',
          google: '구글',
          facebook: '페이스북',
          apple: '애플',
          email: '이메일',
        },
      },
    },
  },
});
