import {
  fetchSignInMethodsForEmail,
  linkWithCredential,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import i18next from 'i18next';
import { captureException } from '@sentry/browser';

import '@pages/app';

import '@components/passwordLogin';
import { globalSpinnerController } from '@components/Spinner';

import {
  isHTMLElement,
  isInputElement,
  isImageElement,
} from '@utils/elementTypeGuards';
import {
  auth,
  idTokenHandler,
  isCalledFromParentSite,
} from '@services/authService';
import COLORS from '@constants/colors';
import { sharedData } from '@shares/dataManager';
import { ACTION_TYPES } from '@constants/actions';
import saveProviderType from '@/utils/saveProviderType';
import getIsNeedOnboarding from '@/utils/getIsNeedOnboarding';

const Enter = 'Enter';

const savedId = localStorage.getItem('savedId');

let formValidationErrors = {
  email: {
    isValid: true,
    message: '',
  },
  password: {
    isValid: true,
    message: '',
  },
};

const PasswordLoginContainer = document.getElementById(
  'password-login-container',
) as HTMLElement;

const emailEl = PasswordLoginContainer.querySelector('#email');
const emailLabel = PasswordLoginContainer.querySelector('#email_label');
const emailError = PasswordLoginContainer.querySelector('#email_error');
const passwordEl = PasswordLoginContainer.querySelector('#password');
const passwordLabel = PasswordLoginContainer.querySelector('#password_label');
const pwVisible = PasswordLoginContainer.querySelector('#pw_visible');
const passwordError = PasswordLoginContainer.querySelector('#password_error');
const checkBox = PasswordLoginContainer.querySelector('#check_id_area');
const saveIdChk = PasswordLoginContainer.querySelector('#save_id');
const loginBtn = PasswordLoginContainer.querySelector('#email-login-btn');
const saveIdBox = PasswordLoginContainer.querySelector('.check_id_label');
const loginExist = PasswordLoginContainer.querySelector('#login_exist');
const resetPw = PasswordLoginContainer.querySelector('.reset-pw');
const forgetPw = PasswordLoginContainer.querySelector('.forget-pw');

const mainPagePath = '/';
const isLinkingSocialAccount = window.location.pathname === mainPagePath;

const doesFormHaveErrors = () => {
  return Object.values(formValidationErrors).some(
    ({ isValid }) => isValid === false,
  );
};

const applyBorderBottomErrorStyle = (element: HTMLElement) => {
  element.style.setProperty(
    'border-bottom',
    `solid ${COLORS.ERROR} 1px`,
    'important',
  );
};

const getIsEmailValidFormat = (email: string) => {
  let expText = /^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-Za-z0-9\-]+/;

  return expText.test(email);
};

const focusAndAnimateError = (
  inputElement: HTMLInputElement,
  errorElement: HTMLElement,
) => {
  inputElement.focus();
  errorElement.animate(
    [
      { transform: 'translate(10px, 0)' },
      { transform: 'translate(0, 0)' },
      { transform: 'translate(10px, 0)' },
      { transform: 'translate(0, 0)' },
      { transform: 'translate(10px, 0)' },
      { transform: 'translate(0, 0)' },
    ],
    {
      duration: 500,
    },
  );
};

if (
  isHTMLElement(PasswordLoginContainer) &&
  isInputElement(emailEl) &&
  isHTMLElement(emailError) &&
  isInputElement(passwordEl) &&
  isImageElement(pwVisible) &&
  isHTMLElement(passwordError) &&
  isHTMLElement(checkBox) &&
  isInputElement(saveIdChk) &&
  isHTMLElement(loginBtn) &&
  isHTMLElement(saveIdBox) &&
  isHTMLElement(loginExist) &&
  isHTMLElement(emailLabel) &&
  isHTMLElement(passwordLabel) &&
  isHTMLElement(resetPw) &&
  isHTMLElement(forgetPw)
) {
  loginBtn.textContent = isLinkingSocialAccount
    ? i18next.t('login.btn.link')
    : i18next.t('login.btn.login');
  emailLabel.textContent = i18next.t('email');
  passwordLabel.textContent = i18next.t('password');
  saveIdBox.textContent = i18next.t('saveId');
  loginExist.textContent = i18next.t('loginWithExistAccount');
  forgetPw.textContent = i18next.t('login.btn.forgetPw');
  resetPw.textContent = i18next.t('login.btn.resetPw');

  if (savedId) {
    saveIdChk.checked = true;
    emailEl.value = JSON.parse(savedId);
  }

  const errorHandler = ({
    type,
    message,
    isValid,
  }: {
    type: 'email' | 'password';
    message: string;
    isValid: boolean;
  }) => {
    formValidationErrors[type] = { isValid, message };
    updateErrorDisplay({ type, message, isValid });
  };

  const updateErrorDisplay = ({
    type,
    message,
    isValid,
  }: {
    type: 'email' | 'password';
    message: string;
    isValid: boolean;
  }) => {
    const errorElement = type === 'email' ? emailError : passwordError;
    const inputElement = type === 'email' ? emailEl : passwordEl;

    errorElement.textContent = message;

    if (isValid) {
      inputElement.style.setProperty('border-bottom', 'none');
    } else {
      applyBorderBottomErrorStyle(inputElement);
    }
  };

  const handleFormValidation = async () => {
    try {
      await handleEmailValidation();
      handlePasswordValidation();
    } catch {}
  };

  const handleEmailValidation = async () => {
    const email = emailEl.value;

    if (email === '') {
      errorHandler({
        type: 'email',
        message: i18next.t('emailEmpty'),
        isValid: false,
      });

      return;
    }

    if (getIsEmailValidFormat(email) === false) {
      errorHandler({
        type: 'email',
        message: i18next.t('emailValidation'),
        isValid: false,
      });

      return;
    }

    const result = await fetchSignInMethodsForEmail(auth, email);
    const isEmailExist = result.includes('password');

    errorHandler({
      type: 'email',
      message: isEmailExist ? '' : i18next.t('userNotFound'),
      isValid: isEmailExist ? true : false,
    });
  };

  const handlePasswordValidation = () => {
    const isPasswordEmpty = passwordEl.value === '';

    errorHandler({
      type: 'password',
      message: isPasswordEmpty ? i18next.t('passwordEmpty') : '',
      isValid: isPasswordEmpty ? false : true,
    });
  };

  const pwVisibleClickHandler = () => {
    pwVisible.src.includes('off')
      ? ((pwVisible.src = './img/visible.svg'), (passwordEl.type = 'password'))
      : ((pwVisible.src = './img/visible_off.svg'), (passwordEl.type = 'text'));

    pwVisible.classList.toggle('pw-visible-margin');
  };

  const checkBoxClickHandler = () => {
    saveIdChk.checked
      ? (saveIdChk.checked = false)
      : (saveIdChk.checked = true);
  };

  const saveIdChkClickHandler = () => {
    saveIdChk.checked
      ? (saveIdChk.checked = false)
      : (saveIdChk.checked = true);
  };

  const redirectToUpdatedCallbackUrl = async ({
    idToken,
    customToken,
    uid,
  }: {
    idToken: string;
    customToken: string;
    uid: string;
  }) => {
    try {
      const { callbackUrl, platform } = sharedData.queries;

      if (callbackUrl) {
        const isWebPlatform = platform === 'web';
        const tokenParam = isWebPlatform ? 'idToken' : 'token';
        const tokenValue = isWebPlatform ? idToken : customToken || '';

        const updatedCallbackUrl = new URL(callbackUrl);
        updatedCallbackUrl.searchParams.append(tokenParam, tokenValue);

        const isNeedOnboarding = await getIsNeedOnboarding({
          uid,
          token: idToken,
        });

        if (isNeedOnboarding) {
          updatedCallbackUrl.pathname = '/onboarding';
        }

        window.location.href = updatedCallbackUrl.toString();
      }
    } catch (error) {
      captureException(error);
    }
  };

  const handleLogin = async () => {
    await handleFormValidation();

    if (doesFormHaveErrors() === true) {
      const inputEl = formValidationErrors.email.isValid ? passwordEl : emailEl;
      const errorEl = formValidationErrors.email.isValid
        ? passwordError
        : emailError;

      focusAndAnimateError(inputEl, errorEl);

      return;
    }

    try {
      globalSpinnerController.showSpinner();
      if (saveIdChk.checked) {
        localStorage.setItem('savedId', JSON.stringify(emailEl.value));
      }

      const userCredential = await signInWithEmailAndPassword(
        auth,
        emailEl.value,
        passwordEl.value,
      );

      if (
        sharedData.credentialForLinkAccount !== null &&
        isLinkingSocialAccount
      ) {
        await linkWithCredential(
          userCredential.user,
          sharedData.credentialForLinkAccount,
        );
      }

      const idToken = await userCredential.user.getIdToken();
      const { customToken } = await idTokenHandler({
        idToken,
        actionType: ACTION_TYPES.EMAIL_SIGNIN,
      });

      if (isCalledFromParentSite) {
        window.opener.postMessage(
          {
            result: 'success',
            data: customToken,
          },
          '*',
        );
      } else {
        redirectToUpdatedCallbackUrl({
          idToken,
          customToken,
          uid: userCredential.user.uid,
        });
      }
      saveProviderType(userCredential.providerId);
    } catch (error: any) {
      //TODO: type 수정 필요
      captureException(error);
      localStorage.removeItem('savedId');
      if (error.code === 'auth/wrong-password') {
        const result = await fetchSignInMethodsForEmail(auth, emailEl.value);

        if (result.includes('password')) {
          passwordError.textContent = i18next.t('wrongPassword');
          applyBorderBottomErrorStyle(passwordEl);

          return;
        }
      }
    } finally {
      globalSpinnerController.hideSpinner();
    }
  };

  loginBtn.addEventListener('click', handleLogin);
  passwordEl.addEventListener('keyup', (event) => {
    if (event.code === Enter) {
      passwordEl.blur();
      handleLogin();
    }
  });
  emailEl.addEventListener('blur', handleEmailValidation);
  passwordEl.addEventListener('blur', handlePasswordValidation);
  pwVisible.addEventListener('click', pwVisibleClickHandler);
  checkBox.addEventListener('click', checkBoxClickHandler);
  saveIdChk.addEventListener('click', saveIdChkClickHandler);

  const displaySocialLoginContainer = async () => {
    PasswordLoginContainer.style.display = 'none';
    const SocialLoginContainer = document.getElementById(
      'social-login-container',
    );
    if (SocialLoginContainer) SocialLoginContainer.style.display = 'block';
  };

  const handlePopState = () => {
    const { display } = window.getComputedStyle(PasswordLoginContainer);
    const isPasswordLoginContainerDisplayed = display === 'block';

    if (isLinkingSocialAccount && isPasswordLoginContainerDisplayed) {
      displaySocialLoginContainer();
    }
  };

  window.addEventListener('popstate', handlePopState);
}
